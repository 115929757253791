<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Mapping rules"
    description="In the list you can review all mapping rules which are linked to this rotation box. Open rule to see included parameters for it."
    primary-button-text="Open mapping rule"
    secondary-button-text="Cancel"
    :disabled="!selectedMapping"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="flex flex-col gap-4">
      <UiInputSearch v-model="search" class="mb-2" :instant="true" @search="search = $event" />

      <div class="flex flex-row flex-wrap gap-4">
        <UiTagSecondary
          v-for="mapping in currentMappings"
          :id="`rule_${mapping.id}`"
          :key="mapping.id"
          :active="selectedMapping === mapping"
          class="cursor-pointer"
          @click="selectedMapping = mapping"
        >
          <template #content>{{ mapping.name }}</template>
        </UiTagSecondary>
      </div>
    </div>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import type { Mapping, Rotation } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  rotation: Rotation
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const search = ref<string>('')
const selectedMapping = ref<Mapping>()

const currentMappings = computed(() =>
  props.rotation.mappings.filter((m) => m.name.toLowerCase().includes(search.value))
)

const submit = () => {
  navigateTo(`/rotations/mappings?query=${selectedMapping.value?.name}`)
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
